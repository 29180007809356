.sectionTitle {
  margin-bottom: 5rem;

  .title {
    font-size: 2rem;
    font-weight: 500;
    text-transform: capitalize;
  }
  .subtitle {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: var(--text-color-secondary);
  }
}
