:root {
  --background-color: #0c0e13;
  --background-color-card: #1c1d23;
  --text-color: white;
  --text-color-secondary: #767a90;

  // Light version
  // @media (prefers-color-scheme: light) {
  //   --background-color: white;
  //   --background-color-card: #e8e9f1;
  //   --text-color: #0e0e0e;
  //   --text-color-secondary: #767a90;
  // }

  --brand-color1: #309bff;
  --brand-color2: #2948ff;
  // Previous gradients
  // --brand-gradient: linear-gradient(to left, #f857a6, #ff5858);
  // --brand-gradient: linear-gradient(to left, #7f00ff, #e100ff);
  // --brand-gradient: linear-gradient(to left, #396afc, #2948ff);

  // Computed brand gradient
  --brand-gradient: linear-gradient(
    to left,
    var(--brand-color1),
    var(--brand-color2)
  );
  --brand-color: var(--brand-color2);

  // Size
  --border-radius: 8px;

  // Animations
  --speed: 150ms;
}

html,
body,
#root {
  background-color: var(--background-color);
  color: var(--text-color);
}

body {
  margin: 0;
  font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

@mixin layout-container {
  max-width: 52rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

// Coloring

@mixin textgradient {
  background-color: var(--brand-color); // Fallback
  background-image: var(--brand-gradient);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

@mixin blurColorDot($size) {
  position: absolute;
  $height: $size;
  // height: 100%;
  $width: 3 * $height;
  max-width: 30vw;
  height: $height;
  width: $width;

  background: radial-gradient(
    ellipse at center,
    var(--brand-color1) 0,
    transparent 75%
  );
  filter: blur(0.4 * $size);
  opacity: 0.6;
}

@mixin hoverText {
  cursor: pointer;
  transition: color var(--speed) ease;
  color: var(--text-color-secondary);
  &:hover {
    color: white;
  }
}
