@use "src/index.scss" as root;

.recentWork {
  @include root.layout-container;

  text-align: center;
  $spacing: 2rem;
  position: relative;

  .cards {
    display: grid;
    // Use auto-fit to prevent emptyness in the right area
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
    grid-gap: $spacing;
  }

  .card {
    display: flex;
    flex-direction: column;
    background-color: var(--background-color-card);
    padding: $spacing;
    border-radius: var(--border-radius);

    > *:not(:last-child) {
      margin-bottom: $spacing;
    }

    .title {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: $spacing / 2;
    }

    .body {
      flex: auto;
    }

    .logo {
      max-width: 14rem;
      height: 3rem;
      margin-left: auto;
      margin-right: auto;
      img {
        width: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .url {
      @include root.hoverText;

      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-left: 0.5rem;
        font-size: 1.2rem;
      }
    }
  }
}
