.footer {
  min-height: 20rem;
  background: var(--brand-gradient);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  .socials {
    svg {
      color: white;
      font-size: 3rem;
    }
    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
