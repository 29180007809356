@use "src/index.scss" as root;

.header {
  $spacing: 2rem;
  .container {
    @include root.layout-container;
    display: flex;
    align-items: center;

    padding-top: $spacing;
    padding-bottom: $spacing;
  }

  .logo {
    font-weight: 600;
    font-size: 1.8rem;
    @include root.textgradient;
    img {
      max-height: 2rem;
    }
  }

  .nav {
    display: flex;
    justify-content: flex-end;

    flex: auto;
    text-align: end;
    margin: 0;
    padding: 0;
    > *:not(:last-child) {
      margin-right: $spacing;
    }

    > * {
      font-weight: 500;
      @include root.hoverText;
    }
  }
}
